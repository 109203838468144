//const SERVER_URL = process.env.REACT_APP_SERVER_URL;
//const SERVER_URL = 'http://localhost:8082/'
//const SERVER_URL = 'http://10.242.233.103:8082/'
//const SERVER_URL = 'https://www.dpcadmin-dev.amgen.com/'


// }
import { REACT_APP_URL_ENV } from "./Endpoint";
// console.log(process.env.NODE_ENV)
// console.log(process.env.REACT_APP_ENV)

// let env= process.env.REACT_APP_ENV.trim()
//  let SERVER_URL = REACT_APP_URL_ENV[env]
// console.log(SERVER_URL)
//let SERVER_URL='http://127.0.0.1:8000/';
export const dummyURL='http://10.242.233.225/api'; 
// export const DummyURLAPi='http://10.242.233.225/api'
let SERVER_URL='http://10.242.233.225/';

export let MULEURL = '';
// export const UPLOADEXCELFILE='uploadFile';
export let UPLOADEXCELFILE='';
export let DOWNLOADPDFRESOURCES='';
export let FETCHESIGNURL='';
export let DOWNLOADFILEACTION='';
export let VALIDATEUSER='';
// export const GETOKTA=  "https://dpc-dev.amgen.com/api/link";
// baseMuleURL+'/downloadResourceFile';
if ("http://localhost:3000" === window.location.origin || "http://localhost:3001" === window.location.origin) {
  //MULEURL = "http://52.36.40.119:8080/api/postController";
  MULEURL = "http://52.36.40.119:8080/api/postController";
  DOWNLOADPDFRESOURCES="http://52.36.40.119:8080/api/downloadResourceFile"
  UPLOADEXCELFILE='http://52.36.40.119:8080/api/uploadFile';
  FETCHESIGNURL='http://52.36.40.119:8080/api/esignlink';
  DOWNLOADFILEACTION='http://52.36.40.119:8080/api/downloadFiles'
  VALIDATEUSER = 'http://52.36.40.119:8080/api/validateUser'
} else {
  MULEURL = window.location.origin + "/api/postController";
  DOWNLOADPDFRESOURCES = window.location.origin + "/api/downloadResourceFile";
  UPLOADEXCELFILE=window.location.origin + "/api/uploadFile";
  FETCHESIGNURL=window.location.origin + "/api/esignlink";
  DOWNLOADFILEACTION=window.location.origin +'/api/downloadFiles';
  VALIDATEUSER = window.location.origin +'/api/validateUser'
  // MULEURL = "http://52.36.40.119:8080/api/postController";
  //  DOWNLOADPDFRESOURCES="http://52.36.40.119:8080/api/downloadResourceFile"
}
//let baseMuleURL='http://10.242.233.225:3000';
let baseMuleURL='https://dpc-dev.amgen.com/api';
// export const xaccesstoken ='veyJraWQiOiJsMVgwd1BiUklTQWMtVmN0cmxpYXFPbXNLaVowTHRpbGNKV2szUVVVRWVNIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULm5iT2hFRkd5aENZLVAzX1otR0RFS1d3bS0zRE44R2F2VU1ES2VqRHhIWVkiLCJpc3MiOiJodHRwczovL2FtZ2VudGVzdC5va3RhcHJldmlldy5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiJhcGk6Ly9kZWZhdWx0IiwiaWF0IjoxNzI3MTA1OTQ3LCJleHAiOjE3MjcxMDk1NDcsImNpZCI6IjBvYTI0cDhzNGRkRVBlSllEMGg4IiwidWlkIjoiMDB1YzR4dnE0anZjbnBiV3AwaDciLCJzY3AiOlsiZW1haWwiLCJvcGVuaWQiLCJwcm9maWxlIl0sImF1dGhfdGltZSI6MTcyNzEwNTk0Niwic3ViIjoic3NlbjAzQGFtZ2VuLmNvbSIsIkdyb3VwcyI6WyJjYXVzYWwtc3R1ZGlvIl0sIkVtcGxveWVlTnVtYmVyIjoiMjE1MDUzIn0.U90ufbNABq8UkefTdk569Pq_MOsUXJ4mebOFcBnBicOsdWg0iNO4MzGKxc098JSJ7yWNd78wDngZghDF56d1D8qdNbXgyXvgGCq9QNsF7jLORCIA7a3SLEAQ4eQqSF985aee3uYrJpq2hAyZ3fGxSweGeb-qwSi_wlYGEIO4CRVWbzU200ULXe-nRxYdr0kAnV2MhCZh-h519K-g4tCzNOmDI0peGkPo5v913z1sji5GU66PmV9rlwEtjLIzGv0jHxtidh1DHsrFpuCRzi307pZpOvZFlZ8or8R6tmT0eD_CZbQacdKSTICN3MNuFh_ywBkBtijIHATpDMthntEsvQ'
// export const  Authorizationbearer= "Bearer eyJraWQiOiJsMVgwd1BiUklTQWMtVmN0cmxpYXFPbXNLaVowTHRpbGNKV2szUVVVRWVNIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVjNHh2cTRqdmNucGJXcDBoNyIsIm5hbWUiOiJTZW4sIFN1ZGV2IiwiZW1haWwiOiJzc2VuMDNAYW1nZW4uY29tIiwidmVyIjoxLCJpc3MiOiJodHRwczovL2FtZ2VudGVzdC5va3RhcHJldmlldy5jb20vb2F1dGgyL2RlZmF1bHQiLCJhdWQiOiIwb2EyNHA4czRkZEVQZUpZRDBoOCIsImlhdCI6MTcyNzEwNTk0NywiZXhwIjoxNzI3MTA5NTQ3LCJqdGkiOiJJRC5GNXJkdVJlQUR3SHBxTkdDcGs4NmxwR1NQUWpJNVd2RVhVczlFemRSQ3hrIiwiYW1yIjpbInB3ZCJdLCJpZHAiOiIwMG81NzdxMWNiUGNzMGRvZDBoNyIsIm5vbmNlIjoibEdQTkhvSzZZZVljYkQ1TXJtamVBdyIsInByZWZlcnJlZF91c2VybmFtZSI6InNzZW4wM0BhbWdlbi5jb20iLCJhdXRoX3RpbWUiOjE3MjcxMDU5NDYsImF0X2hhc2giOiJXVng1N3FqWWZTTW9GNnVnLTZQemlRIiwiY19oYXNoIjoib2toVHJRaWM2MDlFTUlYeHVfSmM5dyIsInVzZXJfZW1haWwiOiJzc2VuMDNAYW1nZW4uY29tIiwiTG9naW5JRCI6InNzZW4wMyIsInN0YWZmX2lkIjoiMjE1MDUzIiwiVXNlcl9JRCI6InNzZW4wMyJ9.KpJ1wuicfGYIkk_KS__T9CMLw_HV2M4HKKnc_gv_xCY-4k_D_K_L5oUFnF9puoLTK5lKW5J6IbAtHGdG8gyo0fL7K3HW9q5GDKEiJffzgK0Gy-v1TtvLjLwdr86Cz0EM8rMwvec8t6rdz-x161KGRcj9xpq7Fsq3ETUPjP-9RdfegXxj-WfUl5ksai0qG2npUrw6P_gpTODBlaDxWSYQFSnjSMjgViQzc8D6MGqOHL71igfclQUKjcUPncFs5w1OeJ7HnBp_zx8R_c0eGHrQRk_STnG9hJGKmXUPMYpxrZrg1sSbMmuI42OgYxyo36t8TiVxOezV7EqgUAsGvjaV0Q"


export const status_colors = {
  "Upload Failed": "#f9c0c0",  
  "Deleted": "#f9c0c0",
  "Stage 1 Approved": "#00FFFF",
  "Stage 2 Pending": "#DEB887",  
  "Data Quality Check Passed": "#aac8f3",  
  "Data Quality Check Rejected": "#FFA07A",  
  "Data Quality Check Pending": "#FFE4C4",
  "Archived": "#F0FFFF", 
  "Stage 1 Rejected": "#f9c0c0",  
  "Data Correction": "#DCDCDC",  
  "Stage 1 Pending": "#F5F5DC",  
  "File Conversion Failed": "#f9c0c0",  
  "Stage 2 Rejected": "#fb8686",  
  "File Conversion Passed": "#7FFFD4",
  "Data Correction Failed": "#FFA07A",
  "Stage 2 Approved": "#7af7b2", 
  "Uploaded": "#2dc1c1"  
}
export const invalidChars = new Set([
    ".",
    "-",
    " ",
    "+",
    "`",
    "~",
    ",",
    "?",
    ":",
    "=",
    ">",
    "<",
    "/",
    "\\",
    "|",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "&",
    "^",
    "%",
    "$",
    "#",
    "@",
    "!",
    "*",
    ";",
    "'",
    '"',
    "`",
    "~",
    "€",
    "£",
    "¥",
    "₹",
    "₽",
    "₩",
  ]);
  export const specialCharRegex = /[|~]/;
  export const timeZones =[
    "(UTC-12:00) International Date Line West",
    "(UTC-11:00) Coordinated Universal Time -11",
    "(UTC-10:00) Hawaii",
    "(UTC-09:00) Alaska",
    "(UTC-08:00) Pacific Time (US and Canada)",
    "(UTC-08:00) Baja California",
    "(UTC-07:00) Mountain Time (US and Canada)",
    "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    "(UTC-07:00) Arizona",
    "(UTC-06:00) Saskatchewan",
    "(UTC-06:00) Central America",
    "(UTC-06:00) Central Time (US and Canada)",
    "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
    "(UTC-05:00) Mexico",
	"(UTC-05:00) US",
	"(UTC+01:00) France",
    "(UTC-05:00) Eastern Time (US and Canada)",
    "(UTC-05:00) Bogota, Lima, Quito",
    "(UTC-05:00) Indiana (East)",
    "(UTC-04:30) Caracas",
    "(UTC-04:00) Atlantic Time (Canada)",
    "(UTC-04:00) Cuiaba",
    "(UTC-04:00) Santiago",
    "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    "(UTC-04:00) Asuncion",
    "(UTC-03:30) Newfoundland",
    "(UTC-03:00) Brasilia",
    "(UTC-03:00) Greenland",
    "(UTC-03:00) Montevideo",
    "(UTC-03:00) Cayenne, Fortaleza",
    "(UTC-03:00) Buenos Aires",
    "(UTC-02:00) Mid-Atlantic",
    "(UTC-02:00) Coordinated Universal Time -02",
    "(UTC-01:00) Azores",
    "(UTC-01:00) Cabo Verde Is.",
    "(UTC-00:00) Dublin, Edinburgh, Lisbon, London",
    "(UTC-00:00) Monrovia, Reykjavik",
    "(UTC-00:00) Casablanca",
    "(UTC-00:00) Coordinated Universal Time",
    "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    "(UTC+01:00) West Central Africa",
    "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "(UTC+01:00) Windhoek",
    "(UTC+02:00) Minsk",
    "(UTC+02:00) Cairo",
    "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "(UTC+02:00) Athens, Bucharest",
    "(UTC+02:00) Jerusalem",
    "(UTC+02:00) Amman",
    "(UTC+02:00) Beirut",
    "(UTC+02:00) Harare, Pretoria",
    "(UTC+02:00) Damascus",
    "(UTC+02:00) Istanbul",
    "(UTC+03:00) Kuwait, Riyadh",
    "(UTC+03:00) Baghdad",
    "(UTC+03:00) Nairobi",
    "(UTC+03:00) Kaliningrad",
    "(UTC+03:30) Tehran",
    "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    "(UTC+04:00) Abu Dhabi, Muscat",
    "(UTC+04:00) Baku",
    "(UTC+04:00) Yerevan",
    "(UTC+04:00) Tbilisi",
    "(UTC+04:00) Port Louis",
    "(UTC+04:30) Kabul",
    "(UTC+05:00) Tashkent",
    "(UTC+05:00) Islamabad, Karachi",
    "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    "(UTC+05:30) Sri Jayawardenepura",
    "(UTC+05:45) Kathmandu",
    "(UTC+06:00) Ekaterinburg",
    "(UTC+06:00) Astana",
    "(UTC+06:00) Dhaka",
    "(UTC+06:30) Yangon (Rangoon)",
    "(UTC+07:00) Novosibirsk",
    "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    "(UTC+08:00) Krasnoyarsk",
    "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    "(UTC+08:00) Kuala Lumpur, Singapore",
    "(UTC+08:00) Taipei",
    "(UTC+08:00) Perth",
    "(UTC+08:00) Ulaanbaatar",
    "(UTC+09:00) Irkutsk",
    "(UTC+09:00) Seoul",
    "(UTC+09:00) Osaka, Sapporo, Tokyo",
    "(UTC+09:30) Darwin",
    "(UTC+09:30) Adelaide",
    "(UTC+10:00) Yakutsk",
    "(UTC+10:00) Canberra, Melbourne, Sydney",
    "(UTC+10:00) Brisbane",
    "(UTC+10:00) Hobart",
    "(UTC+10:00) Guam, Port Moresby",
    "(UTC+11:00) Vladivostok",
    "(UTC+11:00) Solomon Is., New Caledonia",
    "(UTC+12:00) Magadan",
    "(UTC+12:00) Fiji",
    "(UTC+12:00) Auckland, Wellington",
    "(UTC+12:00) Coordinated Universal Time +12",
    "(UTC+13:00) Nuku'alofa"
  ]
  
//let SERVER_URL='http://10.242.233.225/';
export const POSTURL = "http://localhost:4000/api/v1/patterns"
export const DELETEURL = "http://localhost:4000/api/v1/patterns/"
export const PARTNERURL = SERVER_URL+'partners'
//export const GETTEMPLATESTATUS = SERVER_URL+'dpc_admin/home/templatestatus?templates_name='
export const GETTEMPLATESTATUS = SERVER_URL+'getTemplate'
export const GETTEMPLATEAPPROVAL = SERVER_URL+'checkTemplate/'
export const TEMPLTESFORPARTNERS = SERVER_URL+'partnertemplates/'
export const SUBMITPARTNERAPI = SERVER_URL+'submitpartners'
export const SUBMITTEMPLATEAPI = SERVER_URL+'uploadTemplate'
export const FETCHTEMPLATEACTION = SERVER_URL+'dpc_admin/template/getStatus/'
export const TEMPLATESTATUSUPDATE = SERVER_URL+'dpc_admin/action/approvedTemplate'
export const FETCHPARTNERACTION = SERVER_URL+'dpc_admin/home/submitedrequest/'
export const PARTNERHISTORY  = SERVER_URL+'dpc_admin/home/requestHistory'
export const TEMPLATESTATUSVIEW  = SERVER_URL+'dpc_admin/template/getStatusView'
export const TABLES='table';
export const DOWNLOADFILE='downloadFiles'
export const USERTABLES=SERVER_URL+'user/table';
//export const DOWNLOADFILEACTION=baseMuleURL+'/downloadFiles';

export const PARTNERSTATUSUPDATE = SERVER_URL+'dpc_admin/post/approvalPartnerRequest'
export const PARTNERLINKTEMPLATE = SERVER_URL+'dpc_admin/home/getalltemplates/'
export const FETCHDQMDETAILS = SERVER_URL+'dpc_admin/template/getTemplateAudit/'
export const GETTEMPLATEDETAILS = SERVER_URL+'getTemplateData/'
export const GETUSERDETAILS = SERVER_URL+'dpc_admin/template/getUser/'
export const DOWNLOADTEMPLATE = SERVER_URL+'dpc_admin/home/download/'
export const GETTEMPLATEALL = SERVER_URL+'getAllTemplatesNameId'
export const GETTEMPLATETABLE = SERVER_URL+'tablesforTemplate/'

export const GETUSERDETAILSAPI='user';
export const GETUSERROLESAPI='userRoleAccess';
export const GETUPLOADTEMPLATE='templatebyuser';
export const GETUPLOADWORKFLOW='workflows';

export const GETPARTNERROLELIST='partnerRoles';
export const INVITENEWUSER='inviteUser';
export const DOWNLOADTEMPLATERESOURCES=dummyURL+'/downloadFile';
export const DOWNLOADRESOURCE='downloadResource';
export const STATUSUPDATE  ='approve';
export const ARCHIVEUPDATE = 'archiveFile'
export const FETCHRESOURCEVIDEO='downloadResources';
export const FETCHFILEDETAILS = 'viewData'
export const FETCHBASICFILTER = 'filterValues'
export const submitBasicFilter = 'filterValues'
export const FETCHADVANCEFILTER = 'vizData'
export const SETSOPFLAG = 'setSOPFlag'
export const POSTCHATBOT='chatbot'


export const ISACMDQM = [
    {'tabname': 'Data', 'colname': 'CMO Lot #', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'true','display_order':'1'},
    {'tabname': 'Data', 'colname': 'Unit Op Lot #', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'2'},
    {'tabname': 'Data', 'colname': 'Unit Op / Step', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'3'},
    {'tabname': 'Data', 'colname': 'Parameter', 'datatype':  'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'4'},
    {'tabname': 'Data', 'colname': 'Unit of Measure', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'5'},
    {'tabname': 'Data', 'colname': 'Result (numeric) ', 'datatype': 'Double', 'nullcheck': 'false', 'datatypecheck': 'true','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'6'},
    {'tabname': 'Data', 'colname': 'Result (text string)', 'datatype': 'String', 'nullcheck': 'false', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'7'},
    {'tabname': 'Data', 'colname': 'CMO Deviation (if applicable)', 'datatype': 'String', 'nullcheck': 'false', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'8'},
    {'tabname': 'Data', 'colname': 'Comments', 'datatype':'String', 'nullcheck': 'false', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'9'},
   // {'tabname': 'Supplementary Data', 'colname': 'Template Version Number', 'datatype': 'String', 'nullcheck': 'false', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'1'},
  {'tabname': 'Supplementary Data', 'colname': 'Partner', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'1'},
  {'tabname': 'Supplementary Data', 'colname': 'Location', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'2'},
  {'tabname': 'Supplementary Data', 'colname': 'Building or Line', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'3'},
  {'tabname': 'Supplementary Data', 'colname': 'Process Type', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'4'},
  {'tabname': 'Supplementary Data', 'colname': 'Program No.', 'datatype': 'String', 'nullcheck': 'false', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'5'},
  {'tabname': 'Supplementary Data', 'colname': 'INN', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'6'},
  {'tabname': 'Supplementary Data', 'colname': 'Commercial Product Name', 'datatype': 'String', 'nullcheck': 'false', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'7'},
  {'tabname': 'Supplementary Data', 'colname': 'Time Zone', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'8'},
  {'tabname': 'Supplementary Data', 'colname': 'Amgen Lot Number', 'datatype': 'String', 'nullcheck': 'true', 'datatypecheck': 'false','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'9'},
  {'tabname': 'Supplementary Data', 'colname': 'Manufactured Date', 'datatype': 'Timestamp', 'nullcheck': 'true', 'datatypecheck': 'true','visualisation_filter':'false','xaxis':'false','yaxis':'false','colorbyoption':'false','shapebyoption':'false','display_order':'10'}
  
 ]
// export const DeleteButton = require('../images/delete-icon.png')
// export const LoadingWheel = require('../images/loading-wheel.gif')
